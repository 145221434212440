/**
 * Checks if the current environment is development.
 * @returns {boolean} True if the environment is development, false otherwise.
 */
const isDev = () => process.env.NODE_ENV === 'development';

/**
 * Checks if the local storage has the 'DEV_ENV' flag set to 'true'.
 * @returns {boolean} True if 'DEV_ENV' is 'true' in local storage, false otherwise.
 */
const isLocal = () => localStorage.getItem('DEV_ENV') === 'true';

/**
 * Trims leading and trailing whitespace from a string.
 * @param {string|null} str - The string to trim.
 * @returns {string|null} The trimmed string, or null if the input was null.
 */
const trim = (str) => (str ? str.replace(/(^\s+)|(\s+$)/g, '') : str);

/**
 * Checks if an object is empty (i.e., has no properties with non-falsy values).
 * @param {object} obj - The object to check.
 * @returns {boolean} True if the object is empty, false otherwise.
 */
const isEmpty = (obj) => !Object.values(obj).some((value) => (value != null && typeof value === 'object' ? !isEmpty(value) : !!value));

/**
 * Validates if a given URL is valid and optionally checks if it is not a local network URL in production.
 * @param {string} url - The URL to validate.
 * @returns {boolean} True if the URL is valid, false otherwise.
 */
const isValidUrl = (url) => {
	try {
		new URL(url);
		if (!/^(https?|ftps?):\/\/.+/.test(url)) {
			return false;
		}
		return !(process.env.NODE_ENV === 'production' && isLocalNetworkUrl(url));
	} catch {
		return false;
	}
};

/**
 * Checks if a given URL is a local network URL.
 * @param {string} url - The URL to check.
 * @returns {boolean} True if the URL is a local network URL, false otherwise.
 */
const isLocalNetworkUrl = (url) => {
	const localNetworkPatterns = [/^http:\/\/192\.168\.\d{1,3}\.\d{1,3}/, /^http:\/\/10\.\d{1,3}\.\d{1,3}\.\d{1,3}/, /^http:\/\/127\.0\.0\.1/];
	return localNetworkPatterns.some((pattern) => pattern.test(url));
};

/**
 * Constructs an absolute URL from a host and a path.
 * @param {string} host - The base host URL.
 * @param {string} path - The path to append to the host.
 * @returns {string} The constructed absolute URL.
 */
const absoluteUrl = (host, path) => {
	if (isValidUrl(path)) {
		return path;
	}
	const url = new URL(host);
	let uri = url.protocol + '//' + url.host;
	if (path.startsWith('/')) {
		return uri + path;
	} else {
		const pathname = String(url.pathname);
		return uri + pathname.substring(0, pathname.lastIndexOf('/')) + '/' + path;
	}
};

/**
 * Converts a camelCase string to kebab-case.
 * @param {string} str - The camelCase string.
 * @returns {string} The kebab-case string.
 */
const camelToKebab = (str) => str.replace(/[A-Z]/g, (c) => `-${c.toLowerCase()}`);

/**
 * Returns a string from a Date object in the format YYYY-MM-DD.
 * @param {Date} date - The Date object.
 * @returns {string} The formatted date string.
 */
const stringFromDate = (date) => date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);

/**
 * Escapes special characters in a string to be used in a regular expression.
 * @param {string} str - The string to escape.
 * @param {string} [flags] - Optional flags for the regular expression.
 * @returns {RegExp} The escaped regular expression.
 */
const regularExpression = (str, flags) => new RegExp(str.replace(/([.*+?^${}()|[\]/\\])/g, '\\$1'), flags);

/**
 * Converts an object to a query string.
 * @param {object} obj - The object to convert.
 * @returns {string} The query string.
 */
const queryParams = (obj) =>
	Object.entries(obj)
		.map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value))
		.join('&');

/**
 * Gets the first non-null and not undefined value from a list of values.
 * @param {...*} values - The values to check.
 * @returns {*} The first defined value, or undefined if no value is defined.
 */
const firstDefinedValue = (...values) => values.find((value) => value != null);

module.exports = {
	trim,
	isDev,
	isLocal,
	isEmpty,
	isValidUrl,
	absoluteUrl,
	camelToKebab,
	stringFromDate,
	regularExpression,
	queryParams,
	firstDefinedValue,
};
