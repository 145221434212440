// A component showing a modal dialog where a story's JavaScript.

const Vue = require('vue');
const { updateStory } = require('../../data/actions/story');
const locale = require('../../locale');
const { confirm } = require('../confirm');
const { loadFormat } = require('../../data/actions/story-format');
const { firstDefinedValue } = require('../../utils/common');

require('./index.less');

module.exports = Vue.extend({
	template: require('./index.html'),

	data: () => ({
		storyId: null,
		modified: false,
		searchUrl: '',
		embedPDF: false,
		aiResultDisclaimer: '',
		enableAiResultFeedback: false,
		excludedSearchUrlPatterns: [],
		searchQuerySystemPrompt: '',
		searchQueryVariation: 0,
		searchQueryMaxPrevious: 0,
		searchResultSystemPrompt: '',
		searchResultVariation: 0,
		maxSearchResults: 0,
		enableSearchResultQualityCheck: false,
		searchResultQualityCheckSystemPrompt: '',
		searchResultQualityCheckVariation: 0,
		searchResultQualityCheckLowThreshold: 0,
		searchResultQualityCheckMediumThreshold: 0,
		searchResultQualityCheckMediumIntroText: [],
		defaultSettings: {},
	}),

	ready() {
		if (this.story) {
			const previous = this.story.settings || {};
			this.loadFormat(this.story.storyFormat, this.story.storyFormatVersion)
				.then((format) => {
					this.defaultSettings = format?.properties?.defaultSettings || {};
				})
				.finally(() => {
					window.storyFormatDefaultSettings = null;
					const defaults = this.defaultSettings || {};
					if (previous) {
						this.searchUrl = firstDefinedValue(previous.searchUrl, '');
						this.embedPDF = firstDefinedValue(previous.embedPDF, defaults.embedPDF, false);
						this.aiResultDisclaimer = firstDefinedValue(previous.aiResultDisclaimer, '');
						this.enableAiResultFeedback = firstDefinedValue(previous.enableAiResultFeedback, defaults.enableAiResultFeedback, false);
						this.excludedSearchUrlPatterns = firstDefinedValue(previous.excludedSearchUrlPatterns, []);
						this.searchQuerySystemPrompt = firstDefinedValue(previous.searchQuerySystemPrompt, defaults.searchQuerySystemPrompt, '');
						this.searchQueryVariation = firstDefinedValue(previous.searchQueryVariation, defaults.searchQueryVariation, 0);
						this.searchQueryMaxPrevious = firstDefinedValue(previous.searchQueryMaxPrevious, defaults.searchQueryMaxPrevious, 0);
						this.searchResultSystemPrompt = firstDefinedValue(previous.searchResultSystemPrompt, defaults.searchResultSystemPrompt, '');
						this.searchResultVariation = firstDefinedValue(previous.searchResultVariation, defaults.searchResultVariation, 0);
						this.maxSearchResults = firstDefinedValue(previous.maxSearchResults, defaults.maxSearchResults, 3);
						this.enableSearchResultQualityCheck = firstDefinedValue(
							previous.enableSearchResultQualityCheck,
							defaults.enableSearchResultQualityCheck,
							false
						);
						this.searchResultQualityCheckSystemPrompt = firstDefinedValue(
							previous.searchResultQualityCheckSystemPrompt,
							defaults.searchResultQualityCheckSystemPrompt,
							''
						);
						this.searchResultQualityCheckVariation = firstDefinedValue(
							previous.searchResultQualityCheckVariation,
							defaults.searchResultQualityCheckVariation,
							0
						);
						this.searchResultQualityCheckLowThreshold = firstDefinedValue(
							previous.searchResultQualityCheckLowThreshold,
							defaults.searchResultQualityCheckLowThreshold,
							0
						);
						this.searchResultQualityCheckMediumThreshold = firstDefinedValue(
							previous.searchResultQualityCheckMediumThreshold,
							defaults.searchResultQualityCheckMediumThreshold,
							0
						);
						this.searchResultQualityCheckMediumIntroText = Array.isArray(previous.searchResultQualityCheckMediumIntroText)
							? previous.searchResultQualityCheckMediumIntroText
							: defaults.searchResultQualityCheckMediumIntroText || [];
					}
					this.$watch('searchUrl', () => (this.modified = true));
					this.$watch('embedPDF', () => (this.modified = true));
					this.$watch('aiResultDisclaimer', () => (this.modified = true));
					this.$watch('enableAiResultFeedback', () => (this.modified = true));
					this.$watch('excludedSearchUrlPatterns', () => (this.modified = true));
					this.$watch('searchQuerySystemPrompt', () => (this.modified = true));
					this.$watch('searchQueryVariation', () => (this.modified = true));
					this.$watch('searchQueryMaxPrevious', () => (this.modified = true));
					this.$watch('searchResultSystemPrompt', () => (this.modified = true));
					this.$watch('searchResultVariation', () => (this.modified = true));
					this.$watch('maxSearchResults', () => (this.modified = true));
					this.$watch('enableSearchResultQualityCheck', () => (this.modified = true));
					this.$watch('searchResultQualityCheckSystemPrompt', () => (this.modified = true));
					this.$watch('searchResultQualityCheckVariation', () => (this.modified = true));
					this.$watch('searchResultQualityCheckLowThreshold', (newValue) => {
						if (newValue < 1) {
							this.searchResultQualityCheckLowThreshold = 1;
						} else if (newValue > 10) {
							this.searchResultQualityCheckLowThreshold = 10;
						} else if (newValue >= this.searchResultQualityCheckMediumThreshold) {
							this.searchResultQualityCheckMediumThreshold = newValue + 1;
							if (this.searchResultQualityCheckMediumThreshold > 10) {
								this.searchResultQualityCheckMediumThreshold = 10;
							}
						}
						this.modified = true;
					});
					this.$watch('searchResultQualityCheckMediumThreshold', (newValue) => {
						if (newValue < 1) {
							this.searchResultQualityCheckMediumThreshold = 1;
						} else if (newValue > 10) {
							this.searchResultQualityCheckMediumThreshold = 10;
						} else if (newValue <= this.searchResultQualityCheckLowThreshold) {
							this.searchResultQualityCheckLowThreshold = newValue - 1;
							if (this.searchResultQualityCheckLowThreshold < 1) {
								this.searchResultQualityCheckLowThreshold = 1;
							}
						}
						this.modified = true;
					});
					this.$watch('searchResultQualityCheckMediumIntroText', () => (this.modified = true));
				});
		}
	},

	computed: {
		story() {
			return this.allStories.find((story) => story.id === this.storyId);
		},
		isValid() {
			return true;
		},
	},

	methods: {
		resetSearchQuerySystemPrompt() {
			this.searchQuerySystemPrompt = this.defaultSettings.searchQuerySystemPrompt || '';
		},
		resetSearchResultSystemPrompt() {
			this.searchResultSystemPrompt = this.defaultSettings.searchResultSystemPrompt || '';
		},
		resetSearchResultQualityCheckSystemPrompt() {
			this.searchResultQualityCheckSystemPrompt = this.defaultSettings.searchResultQualityCheckSystemPrompt || '';
		},

		save() {
			const settingsToSave = {
				...this.story.settings,
				searchUrl: this.searchUrl,
				assetBaseUrl: this.assetBaseUrl,
				embedPDF: this.embedPDF,
				aiResultDisclaimer: this.aiResultDisclaimer,
				enableAiResultFeedback: this.enableAiResultFeedback,
				excludedSearchUrlPatterns: this.excludedSearchUrlPatterns,
				searchQuerySystemPrompt: this.searchQuerySystemPrompt,
				searchQueryVariation: this.searchQueryVariation,
				searchQueryMaxPrevious: this.searchQueryMaxPrevious,
				searchResultSystemPrompt: this.searchResultSystemPrompt,
				searchResultVariation: this.searchResultVariation,
				maxSearchResults: this.maxSearchResults,
				enableSearchResultQualityCheck: this.enableSearchResultQualityCheck,
				searchResultQualityCheckSystemPrompt: this.searchResultQualityCheckSystemPrompt,
				searchResultQualityCheckVariation: this.searchResultQualityCheckVariation,
				searchResultQualityCheckLowThreshold: this.searchResultQualityCheckLowThreshold,
				searchResultQualityCheckMediumThreshold: this.searchResultQualityCheckMediumThreshold,
				searchResultQualityCheckMediumIntroText: this.searchResultQualityCheckMediumIntroText,
			};
			this.updateStory(this.storyId, { settings: settingsToSave });
			this.modified = false;
			this.$refs.modal.close();
		},

		canClose() {
			if (!this.modified) {
				return true;
			}
			confirm({
				message: locale.say('There were changes detected for the settings dialog. Are you sure you want to discard those changes?'),
				buttonLabel: '<i class="fa fa-trash-o"></i> ' + locale.say('Discard changes'),
				buttonClass: 'danger',
			})
				.then(() => {
					this.$refs.modal.$emit('close');
				})
				.catch(() => {
					// Do nothing
				});
			return false;
		},
	},

	vuex: {
		actions: {
			updateStory,
			loadFormat,
		},
		getters: {
			allStories: (state) => state.story.stories,
		},
	},

	components: {
		'modal-dialog': require('../../ui/modal-dialog'),
		'string-list-editor': require('../../ui/string-list-editor'),
		slider: require('../../ui/slider'),
	},
});
