// A component showing a modal dialog where a story's JavaSCript.

const Vue = require('vue');
const { updateStory } = require('../../data/actions/story');
const locale = require('../../locale');
const { confirm } = require('../confirm');
const { loadFormat } = require('../../data/actions/story-format');
const { firstDefinedValue } = require('../../utils/common');

require('./index.less');

module.exports = Vue.extend({
	template: require('./index.html'),

	data: () => ({
		storyId: null,
		modified: false,
		searchUrl: '',
		embedPDF: false,
		aiResultDisclaimer: '',
		enableAiResultFeedback: false,
		excludedSearchUrlPatterns: [],
		searchQuerySystemPrompt: '',
		searchQueryVariation: 0,
		searchQueryMaxPrevious: 0,
		searchResultSystemPrompt: '',
		searchResultVariation: 0,
		maxSearchResults: 0,
		defaultSettings: {},
	}),

	ready() {
		if (this.story) {
			const previous = this.story.settings || {};
			this.loadFormat(this.story.storyFormat, this.story.storyFormatVersion)
				.then((format) => {
					this.defaultSettings = format?.properties?.defaultSettings || {};
				})
				.finally(() => {
					window.storyFormatDefaultSettings = null;
					const defaults = this.defaultSettings || {};
					if (previous) {
						this.searchUrl = firstDefinedValue(previous.searchUrl, '');
						this.embedPDF = firstDefinedValue(previous.embedPDF, defaults.embedPDF, false);
						this.aiResultDisclaimer = firstDefinedValue(previous.aiResultDisclaimer, '');
						this.enableAiResultFeedback = firstDefinedValue(previous.enableAiResultFeedback, defaults.enableAiResultFeedback, false);
						this.excludedSearchUrlPatterns = firstDefinedValue(previous.excludedSearchUrlPatterns, []);
						this.searchQuerySystemPrompt = firstDefinedValue(previous.searchQuerySystemPrompt, defaults.searchQuerySystemPrompt, '');
						this.searchQueryVariation = firstDefinedValue(previous.searchQueryVariation, defaults.searchQueryVariation, 0);
						this.searchQueryMaxPrevious = firstDefinedValue(previous.searchQueryMaxPrevious, defaults.searchQueryMaxPrevious, 0);
						this.searchResultSystemPrompt = firstDefinedValue(previous.searchResultSystemPrompt, defaults.searchResultSystemPrompt, '');
						this.searchResultVariation = firstDefinedValue(previous.searchResultVariation, defaults.searchResultVariation, 0);
						this.maxSearchResults = firstDefinedValue(previous.maxSearchResults, defaults.maxSearchResults, 3);
					}
					this.$watch('searchUrl', () => (this.modified = true));
					this.$watch('embedPDF', () => (this.modified = true));
					this.$watch('aiResultDisclaimer', () => (this.modified = true));
					this.$watch('enableAiResultFeedback', () => (this.modified = true));
					this.$watch('excludedSearchUrlPatterns', () => (this.modified = true));
					this.$watch('searchQuerySystemPrompt', () => (this.modified = true));
					this.$watch('searchQueryVariation', () => (this.modified = true));
					this.$watch('searchQueryMaxPrevious', () => (this.modified = true));
					this.$watch('searchResultSystemPrompt', () => (this.modified = true));
					this.$watch('searchResultVariation', () => (this.modified = true));
					this.$watch('maxSearchResults', () => (this.modified = true));
				});
		}
	},

	computed: {
		story() {
			return this.allStories.find((story) => story.id === this.storyId);
		},
		isValid() {
			return true;
		},
	},

	methods: {
		resetSearchQuerySystemPrompt() {
			this.searchQuerySystemPrompt = this.defaultSettings.searchQuerySystemPrompt || '';
		},
		resetSearchResultSystemPrompt() {
			this.searchResultSystemPrompt = this.defaultSettings.searchResultSystemPrompt || '';
		},

		save() {
			const settingsToSave = {
				...this.story.settings,
				searchUrl: this.searchUrl,
				assetBaseUrl: this.assetBaseUrl,
				embedPDF: this.embedPDF,
				aiResultDisclaimer: this.aiResultDisclaimer,
				enableAiResultFeedback: this.enableAiResultFeedback,
				excludedSearchUrlPatterns: this.excludedSearchUrlPatterns,
				searchQuerySystemPrompt: this.searchQuerySystemPrompt,
				searchQueryVariation: this.searchQueryVariation,
				searchQueryMaxPrevious: this.searchQueryMaxPrevious,
				searchResultSystemPrompt: this.searchResultSystemPrompt,
				searchResultVariation: this.searchResultVariation,
				maxSearchResults: this.maxSearchResults,
			};
			this.updateStory(this.storyId, { settings: settingsToSave });
			this.modified = false;
			this.$refs.modal.close();
		},

		canClose() {
			if (!this.modified) {
				return true;
			}
			confirm({
				message: locale.say('There were changes detected for the settings dialog. Are you sure you want to discard those changes?'),
				buttonLabel: '<i class="fa fa-trash-o"></i> ' + locale.say('Discard changes'),
				buttonClass: 'danger',
			})
				.then(() => {
					this.$refs.modal.$emit('close');
				})
				.catch(() => {
					// Do nothing
				});
			return false;
		},
	},

	vuex: {
		actions: {
			updateStory,
			loadFormat,
		},
		getters: {
			allStories: (state) => state.story.stories,
		},
	},

	components: {
		'modal-dialog': require('../../ui/modal-dialog'),
		'string-list-editor': require('../../ui/string-list-editor'),
		slider: require('../../ui/slider'),
	},
});
