const locale = require('../locale');

const types = {
	txt: locale.translatable('Text message'),
	buttons: locale.translatable('Buttons'),
	image: locale.translatable('Image'),
	video: locale.translatable('Video'),
	iframe: locale.translatable('iFrame / PDF'),
	carousel: locale.translatable('Carousel'),
	tiles: locale.translatable('Tiles'),
	wait: locale.translatable('Record user input'),
	eval: locale.translatable('Set variable'),
	goto: locale.translatable('Goto'),
	search: locale.translatable('Search'),
	ai: locale.translatable('AI prompt'),
	chat: locale.translatable('Live Chat'),
	// 'sms'     : locale.translatable('SMS (Voice Bot)'),
	// 'call'    : locale.translatable('Call Control (Voice Bot)'),
};

const clipboardType = 'text/plain';

const label = (type) => {
	if (types[type]) {
		return locale.sayNoPotScan(types[type]);
	}
	return type;
};

/* NOTE: all attributes and properties must be initialized for Vue::watch of the GUI to take effect
 */
const createTask = (type, attributes = {}) => {
	const task = {
		type,
		attributes: {
			context: null,
			...attributes,
		},
		content: '',
	};
	switch (type) {
		case 'ai':
		case 'txt':
			task.opt = [];
			break;
		case 'iframe':
			task.attributes['src'] ||= '';
			task.attributes['height'] ||= '';
			task.opt = [];
			break;
		case 'image':
			task.attributes['img'] ||= '';
			task.attributes['alttext'] ||= '';
			task.opt = [];
			break;
		case 'video':
			task.attributes['video'] ||= '';
			task.opt = [];
			break;
		case 'buttons':
			task.buttons = [];
			break;
		case 'carousel':
			task.items = [];
			break;
		case 'tiles':
			task.attributes['caption'] ||= '';
			task.attributes['initial'] ||= 0;
			task.items = [];
			break;
		case 'goto':
			task.attributes['passage'] ||= '';
			break;
		case 'search':
			task.opt = [];
			task.attributes['site'] ||= '';
			task.attributes['query'] ||= '';
			task.attributes['searchtype'] ||= ''; // for some reason 'searchtype' has to be all lower case
			task.attributes['searchengine'] ||= '';
			task.attributes['fallbackengine'] ||= '';
			task.attributes['filterresults'] ||= 'true'; // must be a string, not a boolean!
			break;
		case 'eval':
			task.attributes['eval'] ||= '';
			break;
		case 'wait':
			task.attributes['var'] ||= '';
			task.attributes['validate'] ||= '';
			task.attributes['placeholder'] ||= '';
			task.autocomplete = [];
			break;
	}
	return task;
};

const clipboardTask = () => {
	return navigator.clipboard.read().then((items) => {
		for (const item of items) {
			for (const type of item.types) {
				if (type === clipboardType) {
					return item
						.getType(type)
						.then((blob) => blob && blob.text())
						.then((text) => {
							if (text) {
								try {
									const task = JSON.parse(text);
									if (task.type && types[task.type]) {
										return task;
									}
								} catch (e) {
									console.warn('clipboardTask', e);
								}
							}
						});
				}
			}
		}
	}); // .catch(() => { });
};

module.exports = { label, types, createTask, clipboardTask, clipboardType };
