// A component handling a list of strings used for search result exclusions.

const Vue = require('vue');

require('./index.less');

module.exports = Vue.extend({
	template: require('./index.html'),

	data: () => ({}),

	props: {
		min: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: 100,
		},
		value: {
			type: Number,
			default: 0,
		},
	},

	ready() {},
});
