// A component showing a modal dialog where a story's JavaSCript.

const Vue = require('vue');
const { updateStory } = require('../../data/actions/story');
const locale = require('../../locale');
const { confirm } = require('../confirm');
const { createNewlyLinkedPassages } = require('../../data/actions/passage');

require('./index.less');

module.exports = Vue.extend({
	template: require('./index.html'),

	data: () => ({
		availablePassageNames: null,
		storyId: null,
		origin: null,
		dynamicPassages: [],
		modified: false,
	}),

	ready() {
		this.availablePassageNames = this.story.passages.map((passage) => passage.name).sort();
		this.dynamicPassages = this.story.settings.dynamicPassages || [];

		if (!this.dynamicPassages.length) {
			// ensure one empty entry
			this.add();
		}

		this.$watch('dynamicPassages', () => (this.modified = true), { deep: true });
	},

	computed: {
		gridSize() {
			return 25;
		},
		story() {
			return this.allStories.find((story) => story.id === this.storyId) || {};
		},
	},

	methods: {
		drag(index, event) {
			event.dataTransfer.setData('cb/dynamic-passage', index);
		},
		dragenter(event) {
			if (event.dataTransfer && event.dataTransfer.types.includes('cb/dynamic-passage')) {
				event.preventDefault(); // is allowed
			}
		},
		drop(index, event) {
			const toIdx = index;
			const fromIdx = parseInt(event.dataTransfer.getData('cb/dynamic-passage'), 10);
			// event.dataTransfer.clearData('cb/user-data'); // in Firefox 'clearData' cannot be used here
			if (toIdx !== fromIdx && fromIdx >= 0 && fromIdx < this.dynamicPassages.length) {
				const dynamicPassages = [...this.dynamicPassages];
				const insertIdx = toIdx > fromIdx ? toIdx + 1 : toIdx;
				dynamicPassages.splice(insertIdx, 0, dynamicPassages[fromIdx]);
				const removeIdx = toIdx > fromIdx ? fromIdx : fromIdx + 1;
				dynamicPassages.splice(removeIdx, 1);
				this.dynamicPassages.splice(0, this.dynamicPassages.length, ...dynamicPassages);
				this.modified = true;
			}
		},

		onDialogClicked(e) {
			if (this.searchDropdown && !this.searchDropdown.$el.contains(e.target)) {
				this.closeSearchDropdown();
			}
		},

		add() {
			this.dynamicPassages.push({ pattern: '', passageName: '' });
		},

		remove(index) {
			this.dynamicPassages.splice(index, 1);
			this.modified = true;
		},

		save() {
			const settings = this.story.settings;
			settings.dynamicPassages = this.dynamicPassages;
			this.updateStory(this.storyId, { settings: settings });
			this.modified = false;
			this.$refs.modal.close();
		},

		canClose() {
			if (!this.modified) {
				return true;
			}
			confirm({
				message: locale.say('There were changes detected for the user data dialog. Are you sure you want to discard those changes?'),
				buttonLabel: '<i class="fa fa-trash-o"></i> ' + locale.say('Discard changes'),
				buttonClass: 'danger',
			})
				.then(() => {
					this.$refs.modal.$emit('close');
				})
				.catch(() => {
					// Do nothing
				});
			return false;
		},
		isValidPattern(item) {
			return item.pattern && item.pattern.trim();
		},
		isValidPassageName(item) {
			return this.availablePassageNames.includes(item.passageName);
		},
		isValid() {
			for (const item of this.dynamicPassages) {
				if (!(this.isValidPattern(item) && this.isValidPassageName(item))) {
					return false;
				}
			}
			return true;
		},
		onChange(event, item, index) {
			this.modified = true;
		},
	},

	events: {},

	vuex: {
		actions: {
			updateStory,
			createNewlyLinkedPassages,
		},

		getters: {
			allStories: (state) => state.story.stories,
		},
	},

	components: {
		'modal-dialog': require('../../ui/modal-dialog'),
	},
});
